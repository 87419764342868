<script src="../../../../cultureSaaS-front/webstorm.config.js"></script>
<script src="../../../../webpack.config.js"></script>
<template>
  <div class="login">
    <div class="slogan-container">
      <div class="title-container">
        <div class="title">企业出海一站式服务平台</div>
        <div class="slogan">e路播帮助您快速出海</div>
      </div>
      <img src="@/assets/images/login-icon.png" alt="" />
    </div>
    <div class="login-panel">
      <div class="title">
        <div>您好</div>
        <div>欢迎使用e路播</div>
      </div>
      <a-form autoComplete="off" :form="form">
        <a-form-item class="user-phone">
          <a-input
            size="large"
            placeholder="请输入手机号"
            :maxLength="20"
            @pressEnter="handlerLogin"
            v-decorator.trim="['username', formConfig.username]"
          >
            <i slot="prefix" class="iconfont my-icon icon-icon_zhanghao" />
            <!--<a-icon slot="prefix" type="user" />-->
          </a-input>
        </a-form-item>

        <a-form-item>
          <a-input-password
            size="large"
            placeholder="请输入密码"
            autocomplete="new-password"
            @pressEnter="handlerLogin"
            v-decorator.trim="['password', formConfig.password]"
          >
            <!--<a-icon slot="prefix" type="lock" />-->
            <i
              slot="prefix"
              class="my-icon iconfont icon-mima-15"
              type="icon-mima-15"
            />
          </a-input-password>
        </a-form-item>
        <a-form-item class="auto-login">
          <a-checkbox v-model="autoLogin">自动登录</a-checkbox>
        </a-form-item>

        <a-form-item>
          <a-button
            :loading="loading"
            style="width: 100%"
            size="large"
            shape="round"
            type="primary"
            class="login-btn"
            @click="handlerLogin"
          >
            登录
          </a-button>
        </a-form-item>

        <div class="register">
          <router-link to="/forget-password"> 忘记密码?</router-link>
          <span style="float: right">
            还没有账号?
            <router-link to="/register">去注册</router-link>
          </span>
        </div>
      </a-form>
      <img src="@/assets/images/login-shadow.png" alt="" class="login-shadow" />
    </div>
  </div>
</template>

<script>
import { login } from "@/request/user";
import { mapMutations } from "vuex";
import {
  Form,
  Input,
  Button,
  Icon,
  Row,
  Col,
  Checkbox,
  message,
} from "ant-design-vue";
import md5 from "md5";
import { setUserInfoInLocal } from "@/utils/utils";
import { passwordRule, phoneRule } from "@/config/rules";
import { validatePwd } from "../../config/rules";

export default {
  components: {
    [Form.name]: Form,
    [Form.Item.name]: Form.Item,
    [Input.name]: Input,
    [Input.Password.name]: Input.Password,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Row.name]: Row,
    [Col.name]: Col,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      loading: false,
      username: "",
      password: "",
      form: this.$form.createForm(this),
      autoLogin: true,
      formConfig: {
        username: {
          rules: [
            {
              required: true,
              message: "请输入手机号",
            },
            {
              pattern: phoneRule,
              message: "请输入正确的手机号",
            },
          ],
        },
        password: {
          rules: [
            {
              required: true,
              message: "请输入密码",
            },
            {
              validator: validatePwd,
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.setAutoLogin(this.autoLogin);
  },
  methods: {
    ...mapMutations("user", ["setAutoLogin", "setUserInfo"]),
    handlerLogin() {
      this.form.validateFields((error, values) => {
        if (!error) {
          this.setAutoLogin(this.autoLogin);
          this.loading = true;
          let param = {
            identifier: values.username,
            credential: [md5(values.password)],
            authType: 0,
            scope: 1,
          };
          login(param)
            .then((res = {}) => {
              const { name, phone, id } = res;
              this.loading = false;
              message.success("登录成功");
              const userInfo = {
                id,
                name,
                phone,
              };
              this.setUserInfo(userInfo);
              setUserInfoInLocal(userInfo);
              this.$router.replace({
                path: "/user/info",
              });
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/variants.scss";
@import "@/styles/scss/helper.scss";

.has-error .ant-input,
.has-error .ant-input:hover,
.has-error .ant-input:focus {
  background-color: #fff;
  border-color: #f5222d;
}

::v-deep .ant-input {
  border: 1px solid transparent;

  &:focus {
    border-color: $primary-color;
  }
}

::v-deep .ant-form-explain,
.ant-form-extra {
  margin-top: 0;
}

.login {
  @include flex();
  .slogan-container {
    width: 1142px;
    margin-top: 140px;
    position: relative;

    .title-container {
      //width: 9px;
      position: absolute;
      //top: 70px;
      left: 222px;
      text-align: center;
    }

    .title {
      font-size: 80px;
      font-weight: bold;
      color: #161b2c;
    }

    .slogan {
      font-size: 36px;
      color: #39425c;
      margin-top: 40px;
    }

    img {
      width: 100%;
    }
  }

  .login-panel {
    width: 558px;
    height: 588px;
    background: $white;
    opacity: 0.95;
    border-radius: 10px;
    padding: 64px 50px;
    margin: 140px 124px 0 auto;
    position: relative;

    .my-icon {
      font-size: 20px;
      left: 19px;
      color: rgb(161, 181, 212);
    }

    .title {
      font-size: $font-size-menu-title;
      font-weight: bold;
      color: $primary-text-color;
      line-height: 42px;
      margin-bottom: 40px;
    }

    .ant-form-item {
      margin-bottom: 24px;

      ::v-deep .ant-input,
      ::v-deep .ant-btn {
        height: 56px;
      }

      ::v-deep .ant-input {
        padding-left: 54px;
        background: #eef6ff;
      }

      ::v-deep .ant-input-affix-wrapper .ant-input-prefix {
        left: 19px;
      }
    }

    .user-phone {
      //margin-bottom: 40px;
    }

    .auto-login {
      margin-bottom: 0;
    }

    .login-btn {
      height: 66px !important;
      letter-spacing: 2px;
      margin-top: 36px;
    }

    .register {
      color: $primary-text-color;
      font-size: 20px;
    }

    .login-shadow {
      position: absolute;
      bottom: -94px;
      left: 23px;
      width: 512px;
      height: 94px;
      object-fit: contain;
    }
  }
}

//.login {
//  //height: 100vh;
//  //background: url("./../../assets/images/login/login-bg.png") no-repeat;
//  //background-size: cover;
//  //user-select: none;
//  width: 558px;
//  height: 588px;
//  background: $white;
//  opacity: 0.95;
//  border-radius: 10px;
//  padding: 64px 50px;
//  margin: 140px 124px 0 auto;
//
//  .login-panel {
//    //width: 26.4583vw;
//    //height: 29.4791vw;
//    background-color: #ffffff;
//    border-radius: 10px;
//    position: absolute;
//    left: 50%;
//    top: 50%;
//    margin-top: -14.0625vw;
//    margin-left: 15.625vw;
//    padding: 2.1354vw 1.5625vw;
//
//    h1.title {
//      font-size: 2.0833vw;
//      text-align: center;
//      color: $primary-color;
//      // font-weight: bold;
//      position: relative;
//      margin-bottom: 2.2395vw;
//      &::before,
//      &::after {
//        content: "";
//        display: block;
//        width: 7.3958vw;
//        height: 0.1041vw;
//        background-color: $primary-color;
//        position: absolute;
//        top: 50%;
//        transform: translateY(-50%);
//      }
//      &::before {
//        left: 1.0937vw;
//      }
//      &::after {
//        right: 1.0937vw;
//      }
//    }
//
//    /deep/ input {
//      height: 2.9166vw;
//      background-color: rgba(231, 239, 255, 0.3);
//      color: #3b4664;
//      border: 0;
//      padding-left: 2.8125vw;
//      font-size: 1.0416vw;
//      border-radius: 4px;
//      &::placeholder {
//        color: #a2a6b8;
//        font-size: 0.9375vw;
//      }
//      &:focus {
//        border: 1px solid @primary-color;
//        box-shadow: none;
//      }
//    }
//
//    /deep/ .ant-input-prefix {
//      color: @primary-color;
//      font-size: 0.9895vw;
//      left: 1.0416vw;
//    }
//
//    /deep/ input:-internal-autofill-previewed,
//    /deep/ input:-internal-autofill-selected {
//      font-size: 1.0416vw !important;
//      background-color: #e7efff !important;
//      -webkit-text-fill-color: #3b4664 !important;
//      transition: background-color 5000s ease-in-out 0s !important;
//    }
//
//    .verify-img {
//      width: 100%;
//      height: 2.9166vw;
//      overflow: hidden;
//      position: relative;
//      border-radius: 4px;
//      background: @primary-color;
//      img {
//        width: 100%;
//        height: 2.9166vw;
//        object-fit: cover;
//        position: absolute;
//        left: 50%;
//        top: 50%;
//        transform: translate(-50%, -50%);
//      }
//    }
//
//    .change {
//      width: 100%;
//      height: 2.9166vw;
//      line-height: 2.9166vw;
//      text-align: center;
//      span {
//        color: @primary-color;
//        font-size: 0.7291vw;
//        cursor: pointer;
//      }
//    }
//
//    .errMsg {
//      height: 0.8333vw;
//      line-height: 0.8333vw;
//      margin-bottom: 1.1458vw;
//      font-size: 0.7291vw;
//      color: #de0e0e;
//      text-align: center;
//    }
//
//    .login-btn {
//      height: 2.9166vw;
//      border-radius: 2.9166vw;
//      font-size: 1.25vw;
//      border: 0;
//      background: @primary-color;
//    }
//
//    .register {
//      font-size: 0.7291vw;
//      color: #a2a6b8;
//    }
//  }
//}
</style>
